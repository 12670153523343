<template>
  <div class="main-wrap">
    <span class="tips">以下考试在等待您参加</span>
    <div class="ul-list" v-if="dataList.length">
      <div class="li-item" v-for="(item) in dataList" :key="item.signupuserid">
        <div class="triangle left-top"></div>
        <div class="triangle left-bottom"></div>
        <div class="triangle right-top"></div>
        <div class="triangle right-bottom"></div>
        <div class="side-left">
          <h4 class="title">{{item.examname}}</h4>
          <div class="info">
            <div>
              <p>考试时间：<span>{{item.startdate}}</span></p>
              <p>关联课程：<span>{{item.coursename}}</span></p>
            </div>
            <div>
              <p>考试时长：<span>{{item.duration}}</span></p>
              <p>关联证书：<span>{{item.certifname}}</span></p>
            </div>
          </div>
        </div>
        <div class="side-right">
          <span class="btn btn-hover" @click="goFacePath(item)">人脸识别</span>
          <span class="discern-tips" v-show="!isDisable" :class="{'discern-fail': !isPassed}"><img :src="isPassed ? passImg : failImg" alt="">{{isPassed ? '已通过人脸识别' : '人脸识别异常'}}</span>
          <span class="btn btn-dis" v-if="isDisable" style="margin-top:12px">进入考试</span>
          <span class="btn btn-hover" @click="onOpenModal(item)" v-else style="margin-top:12px">{{isPassed ? '进入考试' : '人工审核'}}</span>
        </div>
      </div>
    </div>
    <a-empty class="empty" style="margin-top:100px" v-else description="暂无考试" />
    <div class="spin">
      <a-spin v-show="loading" />
    </div>
    <a-modal :maskClosable="false" v-model="isExamCodeShow" @ok="handleOk" :closable="false" width="601px">
      <div class="exam-code" v-if="!isManualReview">
        <h3>提示信息</h3>
        <div class="content">
          <p class="font">点击下方“人工审核”按钮，点击后，请保持在当前页面耐心等待。工作人员将会尽快处理您的审核请求。</p>
          <div class="btn-box">
            <a-button type="primary" class="btn" :loading="loadBtn" @click="FunCheckCode">人工审核</a-button>
            <a-button type="primary" class="btn" @click="isExamCodeShow = false" ghost>取消</a-button>
          </div>
        </div>
      </div>
      <div v-if="isManualReview" class="exam-code"  style="margin-top: 24px;margin-bottom: 24px;">
        <div class="content">
          <div style="text-align: center;font-weight: inherit;font-style: inherit;font-family: inherit;font-size: 100%; ">
            <p>
              <span>{{ reviewStatus }}</span>
            </p>
          </div>
          <div style="background-color: #F8F8F8;padding: 24px 20px;font-size: 12px;color: #666666;">
            <div style="margin-bottom: 12px;">
              <div v-if="manualReviewData=='0000'">
                <p>审核中，请保持在当前页面耐心等待。</p>
                <p>工作人员将会尽快审核。若在5分钟内未得到处理，请您拨打电话联系工作人员。</p>
                <p>请勿离开或切换页面，以免影响审核进程。感谢您的配合与等待。</p>
              </div>
              <div v-else-if="manualReviewData=='6666'">
                点击下方按钮进入考试
              </div>
              <div v-else >
                <p>原因：{{manualReviewData}}</p>
              </div>
            </div>
            <div v-if="manualReviewData!='6666'" style="padding-top: 12px;text-align: left;border-top: 1px solid #E6E7E8;color: #666666;">
              <p>联系方式：</p>
              <div>
                <span v-for="item in linkData" :key="item.tellId">{{item.name}}：{{item.mobile}}</span>
              </div>
            </div> 
          </div>
            <div class="btn-box">
              <a-button type="primary" class="btn" :loading="loadBtn" @click="goInfoPath(2)" v-if="manualReviewData==6666">进入考试</a-button>
              <a-button type="primary" class="btn" :loading="loadBtn" @click="FunCheckCode" v-if="manualReviewData!='6666'&&manualReviewData!='0000'">人工审核</a-button>
              <a-button type="primary" class="btn" @click="appyCloseClick" ghost>关闭</a-button>
            </div>
          </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      isDisable: this.$store.state.userInfo.isDisable,  // 是否禁用按钮
      isPassed: this.$store.state.userInfo.isPassed,  // 是否认证通过
      isExamCodeShow:false,  // 考试码弹窗
      isManualReview: false, // 人工审核
      passImg:require('@/assets/image/icon9.png'),
      failImg:require('@/assets/image/icon10.png'),
      dataList: [],
      loading: true,
      loadBtn: false,
      signupuserid: 0,
      examCode:'',  // 考试码
      examName: '',  // 考试名称
      linkData: '', // 联系人
      intervalId: null,
      manualReviewData:null,
      reviewStatus:"审核中"
    };
  },
  // 事件处理器
  methods: {
    // 认证通过后限制再次进入人脸识别
    goFacePath(e){
      if(this.isPassed){
        this.$message.success('已认证通过, 可进入考试!');
        return
      }
      let obj = this.$store.state.userInfo
      obj.configId = e.configid
      obj.signupuserid = e.signupuserid
      obj.examName = e.examname
      this.$store.commit("updateUserInfo", obj);  // 更新个人信息
      if(this.FunCheckBrowser() == 'Safari'){  // Safari浏览器用照片比对
        this.$router.push({ path: "/ExamView/ExamFaceImage" });
      } else {  // 其他用活体
        this.$router.push({ path: "/ExamView/ExamFace" });
      }
    },

    // 检测浏览器
    FunCheckBrowser(){
      var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
      var isOpera = userAgent.indexOf("Opera") > -1;
      if (isOpera) {//判断是否Opera浏览器
        return "Opera"
      }; 
      if (userAgent.indexOf("Firefox") > -1) {//判断是否Firefox浏览器
        return "FF";
      } 
      if (userAgent.indexOf("Chrome") > -1){//判断是否Chrome浏览器
        return "Chrome";
        }
      if (userAgent.indexOf("Safari") > -1) {//判断是否Safari浏览器
        return "Safari";
      } 
      if (userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1 && !isOpera) {//判断是否IE浏览器
        return "IE";
      }; 
    },

    // 校验考试码
    FunCheckCode(){
      this.loadBtn = true
      let obj = this.$store.state.userInfo
      this.reviewStatus = (obj.examCode=="6666"?"已通过":(obj.examCode!="0000"?"未通过":"审核中"));
      this.loadBtn = true
      this.$ajax({
        method: 'put',
        url:"/exam/system/submitManualReview/"+obj.examineeId
      }).then(res => {
        this.loadBtn = false
        if (res.code == 200 && res.success) {
          this.isManualReview = true;
          this.startPolling();
        }
      })
    },
    // 打开考试码弹窗
    onOpenModal(e){
      this.isExamCodeShow = true
      this.signupuserid = e.signupuserid
      this.examName = e.examname
      // 根据状态显示人工审核信息
      if(e.examCode != null && e.examCode != undefined){
        this.manualReviewData = e.examCode;
        this.isManualReview = (e.examCode!=""||e.examCode!=null?true:false);
        this.reviewStatus= (e.examCode=="6666"?"已通过":(e.examCode=="0000"?"审核中":"未通过"));
        if(e.examCode=="0000"){
          this.startPolling();
        }
      }
      if(!this.isPassed){
        this.$ajax({
          method: 'get',
          url:"/exam/system/tell-list",
          params:{
            configId: e.configid,
          }
        }).then(res => {
          if (res.code == 200 && res.success) {
            this.linkData = res.data
          } else {
            this.$message.error('工作人员联系方式查询错误！');
            this.isExamCodeShow = false
          }
        })
      } else {
        this.goInfoPath(this.$store.state.userInfo.entyMode)
      }
    },
    // 前往个人信息页面
    goInfoPath(type){
      let obj = this.$store.state.userInfo
      
      // type 进入方式 1.人脸识别 2.考试码
      if(type != 2){
        this.$ajax({
          method: 'put',
          url:"/exam/system/edit?entyMode=" + type + '&examineeId=' + obj.examineeId,
        })
      }

      obj.isSubmit = false  // 是否提交试卷
      obj.examName = this.examName
      obj.signupuserid = this.signupuserid
      this.$store.commit("updateUserInfo", obj);  // 更新个人信息
      this.$router.push({ path: "/ExamView/ExamInfo" });
    },
    // 弹窗确认
    handleOk(){

    },
    // 获取考试列表
    getExamList(){
      this.loading = true
      this.$ajax({
        method: 'get',
        url:"/exam/system/user/signup/list",
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.dataList = res.data
        } else {
          this.$message.error(res.message);
        }
      })
    },
    async fetchAuditStatus(){
      this.$ajax({
        method: 'get',
        url:"/exam/system/getManualReviewStatus/"+this.$store.state.userInfo.examineeId
      }).then(res => {
        if (res.code == 200 && res.success) {
         this.manualReviewData = res.data;
         this.reviewStatus = (res.data=="6666"?"已通过":(res.data!="0000"?"未通过":"审核中"));
         if(this.manualReviewData!='0000'){
          this.stopPolling();
         }
        }
      })
    },
    startPolling() {
      // 设置定时器，每分钟调用fetchData方法
      this.intervalId = setInterval(this.fetchAuditStatus, 10000);
      // 立即执行一次请求
      this.fetchAuditStatus();
    },
    stopPolling() {
      // 清除定时器
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },
    appyCloseClick(){
      this.isManualReview=false;
      this.isExamCodeShow=false;
      this.manualReviewData=null;
      this.getExamList();
    }
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getExamList()
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
  beforeDestroy () {
    this.stopPolling();
  }
};
</script>

<style lang="less" scoped>
.main-wrap {
  text-align: left;
  .tips {
    font-size: 18px;
    color: #333;
  }
  .ul-list {
    // padding: 0 10px;
    margin-top: 20px;
    // overflow-y: scroll;
    width: 100%;
    max-height: calc(100vh - 180px);
    .li-item {
      position: relative;
      margin-bottom: 26px;
      padding: 30px;
      border-top: 2px dashed #2d74dd;
      border-bottom: 2px dashed #2d74dd;
      display: flex;
      justify-content: space-between;
      min-width: 680px;
      .triangle {
        position: absolute;
        width: 25px;
        height: 25px;
      }
      .left-top {
        position: absolute;
        border-left: solid #2d74dd;
        border-top: solid #2d74dd;
        left: -3px;
        top: -3px;
      }
      .left-bottom {
        position: absolute;
        border-left: solid #2d74dd;
        border-bottom: #2d74dd solid;
        left: -3px;
        bottom: -3px;
      }
      .right-top {
        position: absolute;
        border-right: solid #2d74dd;
        border-top: solid #2d74dd;
        right: -3px;
        top: -3px;
      }
      .right-bottom {
        position: absolute;
        border-right: solid #2d74dd;
        border-bottom: solid #2d74dd;
        right: -3px;
        bottom: -3px;
      }
      .side-left {
        width: 78%;
        .title {
          color: #333333;
          font-size: 24px;
          font-weight: 500;
        }
        .info{
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          min-width: 480px;
          p{
            margin-top: 5px;
            min-width: 230px;
            line-height: 23px;
            text-align: left;
            font-size: 16px;
            font-weight: 400;
            color: #666666;
            span{
              color: #333;
            }
          }
        }
      }
      .side-right{
        width: 134px;        
        .btn{
          display: inline-block;
          text-align: center;
          color: #fff;
          font-size: 18px;
          width: 134px;
          height: 45px;
          line-height: 45px;
          background: linear-gradient(180deg, #3681F0 0%, #2263C5 100%);
          border-radius: 4px 4px 4px 4px;
          position: relative;
          overflow: hidden;
          cursor: pointer;
        }
        .btn-dis{
          background: #C4C4C4;
        }
        .btn-hover{
          &:hover::before{
            left: 100%;
          }
          &::before{
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: -100%;
            background: linear-gradient(90deg,transparent,hsla(0,0%,100%,.5),transparent);
            transition: all .5s;
          }
        }
        .discern-tips{
          display: block;
          margin-top: 3px;
          text-align: center;
          color: #83CE82;
          font-size: 14px;
          img{
            margin-right: 3px;
            position: relative;
            top: -1px;
          }
        }
        .discern-fail{
          color: #FF9F99;
        }
      }
    }
    .li-item:last-child{
      margin-bottom: 0;
    }
  }
  .ul-list::-webkit-scrollbar {
    display: none;
  }
  .spin{
    margin-top: -100px;
    width: 100%;
    text-align: center;
    height: 100%;
  }
}

.exam-code{
  padding: 22px 30px;
  >h3{
    font-size: 18px;
    font-weight: 500;
    color: #2D74DD;
  }
  .content{
    margin: 0 30px;
    .input-code{
      display: flex;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 17px;
      >span{
        display: inline-block;
        width: 70px;
        font-weight: 500;
        color: #333333;
      }
    }
    .font{
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      padding: 20px 0px;
    }
    .tell-phone{
      // display: flex;
      // justify-content: space-between;
      margin-top: 5px;
      overflow: hidden;
      .font{
        float: left;
        width: calc(100% / 2);
      }
    }
    .btn-box{
      text-align: right;
      margin-top: 20px;
      .btn{
        width: 90px;
      }
      .btn:nth-child(1){
        background: linear-gradient(180deg, #3681F0 0%, #2263C5 100%);
        margin-right: 20px;
      }
    }
  }
}

/deep/ .ant-modal-header{
  display: none;
}
/deep/ .ant-modal-body{
  padding: 0;
}
/deep/ .ant-modal-footer{
  display: none;
}
</style>
